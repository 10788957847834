#topMain>li>a {
	height:96px;
	line-height:76px;
}
#topMain.nav-pills>li>a {
	color:#1F262D;
	font-weight:400;
	background-color:transparent;
} 
#topMain.nav-pills>li:hover>a, 
#topMain.nav-pills>li:focus>a {
	color:#1F262D;
	background-color:rgba(0,0,0,0.03);
}
#topMain.nav-pills>li.active>a {
	color:#687482;
}

#topMain.nav-pills>li>a.dropdown-toggle {
	padding-right:25px;

	-webkit-border-radius: 0;
	   -moz-border-radius: 0;
			border-radius: 0;
}
#topMain.nav-pills>li>a.dropdown-toggle:after {
	display: block;
	content: "\f107";
	position: absolute;
	top: 50%;
	right: 10px;
	margin: -5px 0 0;
	font-family: FontAwesome;
	font-size: 12px;
	opacity: .3;
	line-height: 1em;

	-webkit-transition: -webkit-transform .2s ease-in;
	-moz-transition: -moz-transform .2s ease-in;
	-ms-transition: -ms-transform .2s ease-in;
	-o-transition: -o-transform .2s ease-in;
	transition: transform .2s ease-in;
}
#header.bottom.dropup #topMain.nav-pills>li>a.dropdown-toggle:after {
	content: "\f106";
}

#topNav .navbar-collapse {
	float:right;
}

#topNav a.logo {
	height:96px;
	line-height:96px;
	overflow:hidden;
	display:inline-block;
}



@media only screen and (max-width: 1215px) {
	#topMain.nav-pills>li>a {
		font-size:13px;
	}
}

@media only screen and (max-width: 992px) {
	/* Force 60px */
	#header {
		height:60px !important;
	}
	#header #topNav a.logo {
		height:60px !important;
		line-height:50px !important;
	}
	#header #topNav a.logo>img {
		max-height:60px !important;
	}
	#header #topNav #topMain>li>a {
		height:40px !important;
		line-height:40px !important;
		padding-top:0;
	}


	#topMain>li {
		border-bottom:rgba(0,0,0,0.1) 1px solid;
	}
	#topMain>li:last-child {
		border-bottom:0;
	}

		#header li.search .search-box {
			margin:0 !important;
			position:fixed;
			left:0; right:0;
			top:60px !important;
			width:100%;
			background-color:#fff;
			border-top:rgba(0,0,0,0.1) 1px solid;
		}
}